import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Banner from './Banner';
import NewTweetForm from './NewTweetForm';
import TweetCard from './TweetCard';
import { useAuth } from '../context/AuthContext';

const Tweets = memo(({ tweetService, username, addable }) => {
  const [tweets, setTweets] = useState([]);
  const [error, setError] = useState('');
  const history = useHistory();
  const { user } = useAuth();

  useEffect(() => {
    tweetService
      .getTweets(username)
      .then((tweets) => setTweets([...tweets]))
      .catch(onError);

    const stopSync = tweetService.onSync((tweet) => onCreated(tweet));
    return () => stopSync();
  }, [tweetService, username, user]);

  const teacherBtnWords = [
    '학습전략 개선법',
    '학습목표 도달수준',
    '도움질문 제공하기',
    '예시 보여주기',
    '구체적 피드백',
    '학습과제 제시하기',
    '따뜻한 격려',
    '1주일 후 기억?',
  ];

  const onCreated = (tweet) => {
    setTweets((tweets) => [tweet, ...tweets]);
  };

  const onDelete = (tweetId) =>
    tweetService
      .deleteTweet(tweetId)
      .then(() => setTweets((tweets) => tweets.filter((tweet) => tweet.id !== tweetId)))
      .catch((error) => setError(error.toString()));

  const onUpdate = (tweetId, text) =>
    tweetService
      .updateTweet(tweetId, text)
      .then((updated) => setTweets((tweets) => tweets.map((item) => (item.id === updated.id ? updated : item))))
      .catch((error) => error.toString());

  const onUsernameClick = (tweet) => history.push(`/${tweet.username}`);

  const onError = (error) => {
    setError(error.toString());
    setTimeout(() => {
      setError('');
    }, 3000);
  };
  const [form, setForm] = useState([]);
  const showForm = (i) => {
    setForm(!form);
  };

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {teacherBtnWords.map(function (feedbackName, i) {
          return (
            <>
              <button
                key={i}
                onClick={showForm}
                style={{
                  margin: '5px',
                  width: '150px',
                  height: '40px',
                  fontSize: '15px',
                  background: '#317CA3',
                  borderRadius: '20px',
                }}
                type='submit'
              >
                {teacherBtnWords[i]}
              </button>
            </>
          );
        })}
      </div>

      {form && addable && <NewTweetForm tweetService={tweetService} onError={onError} />}
      {error && <Banner text={error} isAlert={true} transient={true} />}
      {tweets.length === 0 && <p className='tweets-empty'>게시된 글이 없습니다.</p>}
      <ul className='tweets'>
        {tweets.map((tweet) => (
          <TweetCard
            key={tweet.id}
            tweet={tweet}
            owner={tweet.username === user.username}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onUsernameClick={onUsernameClick}
          />
        ))}
      </ul>
    </>
  );
});
export default Tweets;
