import React, { useState } from 'react';

const NewTweetForm = ({ tweetService, onError }) => {
  const [tweet, setTweet] = useState('');
  const onSubmit = async (event) => {
    event.preventDefault();
    tweetService
      .postTweet(tweet)
      .then((created) => {
        setTweet('');
      })
      .catch(onError);
  };

  const onChange = (event) => {
    setTweet(event.target.value);
  };

  return (
    <form className='tweet-form' onSubmit={onSubmit}>
      <textarea
        placeholder={`1. 학습전략 개선법: 학생 스스로 학습 전략을 개선하도록 유도한다.
2. 학습목표 도달수준: 학생의 현재 상태와 도달해야 할 상태 사이의 차이를 인식하고, 그 차이를 좁히도록 한다. 
3. 도움질문 제공하기: 학생이 문제를 해결할 수 있도록 돕는 질문을 사용하여 비계(scaffolding)을 제공한다.
4. 예시 보여주기: 교사가 학생들에게 해야 할 것에 대한 모델들을 제시하고, 학생들이 이들 모델 중 하나를 사용하게 하거나, 교사가 제시한 모델을 바탕으로 스스로 만들게 한다. 
5. 구체적 피드백: 피드백 대상이 되는 학생의 과제가 학습목표와 관련되고, 성공적인 수행 기준이 구체적이어야 한다.
6. 학습과제 제시하기: 학생의 성취 수준에 맞는 학습 과제를 제시한다.
7. 따뜻한 격려: 학생에게 1인칭, 3인칭을 사용하고 2인칭을 사용하지 않는다. 피드백은 학생을 존중하고 지원하는 어조를 사용한다. 
8. 1주일 후 기억?: 메타인지 학습법에 따라 공부를 하면서 '내가 얼마나 잘 기억할 것인가?'가 아니라 '내가 얼마나 더 잘 잊어버릴 것인가?'를 염두에 두게 한다.`}
        value={tweet}
        required
        autoFocus
        onChange={onChange}
        className='form-input tweet-input'
      />
      <button
        type='submit'
        style={{
          margin: '0 auto',
          fontSize: '20px',
          width: '100%',
          height: '6vh',
          borderRadius: '20px',
          background: 'black',
        }}
      >
        게시하기
      </button>
    </form>
  );
};

export default NewTweetForm;
