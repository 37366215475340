import React, { memo } from 'react';

const Header = memo(({ username, onLogout, onMyTweets, onAllTweets }) => {
  return (
    <header className='header'>
      <div className='logo'>
        <h1 className='logo-name'>메타노트</h1>
        {username && <span className='logo-user'>@{username}</span>}
      </div>
      {username && (
        <nav className='menu'>
          <button onClick={onAllTweets}>모든 글</button>
          <button onClick={onMyTweets}>나의 글</button>
          <button className='menu-item' onClick={onLogout}>
            로그아웃
          </button>
        </nav>
      )}
    </header>
  );
});

export default Header;
